.signout-container{
    text-align: center;
    justify-content: center;
    

}

.signout-container .signout-main{
    margin-top: 120px;

}