.home-container {
  /* background-color: black; */

  text-align: center;
}
.main-area {
  justify-content: space-around;
  margin-left: 30px;
  margin-right: 30px;
}
.main-area img {
  width: 100%;
  height: 100%;
  border: 5px;
  border-radius: 2px;
}
.main-area p {
  text-align: justify;
  font-weight: 500;
  /* color: white; */
}
