.navbar-qrcode{
text-decoration: none;
margin-right: 10px;
}
.company-logo{
    width: 50px;
    height: 50px;
    border-radius: 5px;
}
.navbar-learn{
    margin-right: 10px;
}
