.isurnace-coantiner{
    text-align: center;
}
.isurnace-coantiner .isnurance-header{

}
.isurnace-coantiner .insurnace-mainarea{

}
.isurnace-coantiner .insurnace-footer{
    
}
