* {
}
.afterscan-main {
  text-align: center;
}
.afterscan-main .buttions{
  margin-top: 20px;
  margin-bottom: 50px;

}
.afterscan-main .buttions button{
  margin-left: 10px;
  margin-right: 10px;
}
.afterscan-prescribe-drug button {
  margin-top: 0px;
  margin-left: 5px;
  margin-right: 5px;
  width: 60px;
  height: 40px;
  border-style: inset;
  border: 2px solid black;
  border-radius: 10px;
  color: black;
  /* background-color: white; */
  /* background-color: blue; */
}
.afterscan-prescribe-drug input {
  border-radius: 10px;
  padding-left: 10px;
  width: 90%;
  height: 40px;
  /* background-color: brown; */
}
.afterscan-prescribe-drug {
  display: flex;
}
.afterscan-prescribe-drug .interval {
  width: 40px;
  height: 40px;
  border-style: inset;
  border: 0px solid black;
  border-radius: 0px;
  color: black;
  /* background-color: white; */
  /* background-color: blue; */
}

