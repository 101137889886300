.mission-main{
    margin-right: 30px;
    margin-left: 30px;
}
.mission-container{
    text-align: center;
}

.mission-main-top {
    text-align: justify;
}
