.dashboard-mainarea {
  text-align: center;
}
.patient-adminitration-dashboard p {
  text-align: left;
  margin-left: 20px;
}

.patient-adminitration-dashboard .dashboard-button button {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
  margin: 10px;
}
.patient-adminitration-dashboard .dashboard-button button:hover {
  color: white;
  background-color: blueviolet;
}
.patient-adminitration-dashboard .afterscan-prescribe-drug {
  margin-top: 10px;
  margin-left: 10px;
}
.patient-adminitration-dashboard .afterscan-prescribe-drug button {
  height: auto;
  width: auto;
  text-align: center;

  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
}
.patient-adminitration-dashboard .dashboard-pagination {
  margin-left: 10px;
}
.patient-adminitration-dashboard .patient-adminitration-navigationbar {
  display: flex;
  padding: 0%;
  text-align: center;
}
.patient-adminitration-dashboard
  .patient-adminitration-navigationbar
  .search-bar {
  width: 90%;
  /* margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px; */
  margin: 20px;
}

.patient-adminitration-dashboard
  .patient-adminitration-navigationbar
  .notification-bar
  button {
  border: 0px;
  border-radius: 0px;
  margin: 0px;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  margin-right: 20px;
}
