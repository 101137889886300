.testingcenter-container{
    text-align: center;
}
.testingcenter-container .testingcenter-header{

}
.testingcenter-container .testingcenter-mainarea{
    
}
.testingcenter-container .testingcenter-footer{
    
}