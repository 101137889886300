.pharma-container{
    text-align: center;
}
.pharma-container .pharma-header{

}
.pharma-container .pharma-mainarea{

}
.pharma-container .pharma-footer{
    
}