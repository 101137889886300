.roadmap-main-area{
    text-align: center;
    background-color: black;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
    
}
.roadmap-for-implementation{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    text-align: left;
    color: white;
    
  }
  .roadmap-for-implementation ul li img{
  width: 25px;
  height: 25px;
  }
  .phase-common-css{
    border: 4px aqua solid;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 7px;
    background: linear-gradient(217deg, rgba(202, 102, 102, 0.8), rgba(255,0,0,0) 70.71%),
              linear-gradient(127deg, rgba(23, 34, 23, 0.8), rgba(0,255,0,0) 70.71%),
              linear-gradient(336deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.71%);
  }
 